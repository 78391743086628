<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="py-12 bg-white md:py-32">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div>
        <h2 class="text-base font-semibold tracking-wide uppercase text-primary-600">
          {{ subtitle }}
        </h2>
        <p class="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
          {{ title }}
        </p>
        <p class="max-w-2xl mt-4 text-xl text-gray-500 lg:mx-auto">
          {{ description }}
        </p>
      </div>

      <div class="mt-10">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <div
            v-for="item in itemList"
            :key="item.title"
            class="flex"
          >
            <div class="flex-shrink-0">
              <div class="flex items-center justify-center w-12 h-12 text-white rounded-md bg-primary-900">
                <i :class="iconText(item.icon)" /> 
              </div>
            </div>
            <div class="ml-4">
              <dt class="text-lg font-medium leading-6 text-gray-900">
                {{ item.title }}
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                {{ item.description }}
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props : {
        title : {
            type: String,
            required : true
        },
        subtitle : {
            type: String,
            required : true
        },
        description : {
            type: String,
            required : true
        },
        itemList : {
            type : Array,
            required : true
        },
    },
    methods:{
        // TODO register globally
        iconText(icon){
            return `${icon} fa-lg`;
        }
    }
};
</script>

<style>
div > i {
    text-align: center;
    vertical-align: middle;
}
</style>