<template>
  <div class="relative bg-white md:py-16">
    <div class="absolute inset-0">
      <div class="absolute inset-y-0 left-0 w-1/2 bg-white" />
    </div>
    <div class="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
      <div class="px-4 py-16 bg-white lg:border-r lg:border-gray-900 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
        <div class="max-w-lg mx-auto">
          <h2 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
            Get in touch
          </h2>
          <p class="mt-3 text-lg leading-6 text-gray-500">
            Don't wait to get started ! Simplicity made easy.
          </p>
          <dl class="mt-8 text-base text-gray-500">
            <div>
              <dt class="sr-only">
                Postal address
              </dt>
              <dd>
                <p>Kingston, Ontario, Canada</p>
              </dd>
            </div>
            <div class="mt-6">
              <dt class="sr-only">
                Phone number
              </dt>
              <dd class="flex">
                <!-- Heroicon name: phone -->
                <svg
                  class="flex-shrink-0 w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <span class="ml-3">
                  +1 (613)-929-9667
                </span>
              </dd>
            </div>
            <div class="mt-3">
              <dt class="sr-only">
                Email
              </dt>
            </div>
          </dl>
        </div>
      </div>
      <div class="px-4 py-16 bg-white sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
        <div class="max-w-lg mx-auto lg:max-w-none h-full">
          <form
            v-if="!hideForm"
            class="grid grid-cols-1 gap-y-6"
            @submit.prevent="sendForm"
          >
            <div>
              <label
                for="full_name"
                class="sr-only"
              >Full name</label>
              <input
                id="full_name"
                v-model="form.fullname"
                type="text"
                name="full_name"
                autocomplete="name"
                class="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
                placeholder="Full name"
              >
            </div>
            <div>
              <label
                for="email"
                class="sr-only"
              >Email</label>
              <input
                id="email"
                v-model="form.email"
                name="email"
                type="email"
                autocomplete="email"
                class="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
                placeholder="Email"
              >
            </div>
            <div>
              <label
                for="phone"
                class="sr-only"
              >Phone</label>
              <input
                id="phone"
                v-model="form.phone"
                type="text"
                name="phone"
                autocomplete="tel"
                class="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
                placeholder="Phone"
              >
            </div>
            <div>
              <label
                for="message"
                class="sr-only"
              >Message</label>
              <textarea
                id="message"
                v-model="form.message"
                name="message"
                rows="4"
                class="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
                placeholder="Message"
              />
            </div>
            <div>
              <button
                type="submit"
                class="inline-flex justify-center px-6 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-800 hover:bg-primary-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                Submit
              </button>
            </div>
          </form>
          <div
            v-else
            class="flex justify-center h-full items-center"
          >
            <h2 class="text-2xl font-extrabold tracking-tight text-gray-700 sm:text-3xl">
              Your message has been sent!
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data: () => ({
        form : {
            fullname : '',
            email: '',
            phone: '',
            message: '',
        },
        hideForm: false
    }),
    methods:{
        async sendForm() {

            const URL = 'https://formspree.io/f/mdovpwra';

            const response = await fetch(URL, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify({...this.form}), // body data type must match "Content-Type" header
            });
            const res = await response.json();
            if(res.ok) {
                // clear form
                this.hideForm = true;
            }
            else {

            }
            return res;
        }
    }
};
</script>

<style>

</style>