<template>
  <div class="bg-primary-900 text-gray-50">
    <div class="flex px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8 md:flex-col">
      <div class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
        <div>
          <img
            class="image"
            src="@/assets/images/baseball.jpg"
            alt=""
          >
        </div>
        <div class="flex lg:mr-24">
          <div class="flex flex-col justify-center">
            <h2 class="text-2xl font-extrabold tracking-tight capitalize text-gray-50 sm:text-3xl md:text-4xl lg:text-3xl xl:text-4xl">
              {{ title }}
            </h2>
            <p class="max-w-md mx-auto mt-3 text-lg text-gray-200 sm:text-xl md:mt-5 md:max-w-3xl">
              {{ description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props : {
        title : {
            type : String,
            required : true
        },
        description : {
            type : String,
            required : true
        }
    }
};
</script>

<style>
    .image{
        height: 600px;
        width: auto;
    }
</style>