<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative bg-gray-50">
      <main class="lg:relative">
        <div class="w-full pt-16 pb-20 mx-auto text-center max-w-7xl lg:py-48 lg:text-left">
          <div class="px-4 lg:w-1/2 sm:px-16">
            <transition
              name="slide"
              mode="out-in"
            >
              <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                <span class="block xl:inline">Simplicity made easy.</span>
              </h1>
            </transition>
            <p class="max-w-md mx-auto mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              De-Clutter and Organize your home.
            </p>
            <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div class="rounded-md shadow">
                <button
                  class="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white border border-transparent rounded-md bg-primary-800 hover:bg-primary-900 md:py-4 md:text-lg md:px-10"
                  @click="scrollContact()"
                >
                  Contact us
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <img
            class="absolute inset-0 object-cover w-full h-full"
            src="../assets/images/fruit_bowl.jpg"
            alt=""
          >
        </div>
      </main>
    </div>
  </div>
</template>

<script>
//import heroImage from '../assets/images/Hero.jpg';

export default {
    methods : {
        scrollContact(){
            this.$emit('clicked');
        }
    }
};
</script>

<style>
.slide-leave-active,
.slide-enter-active{
  transition: all 0.25s;
}

.slide-leave-to,
.slide-enter-from {
  opacity: 0;
  transform: translateX(-5%);
}


.slide-enter-to{
  opacity: 1;
}
</style>