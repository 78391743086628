<template>
  <div>
    <v-hero 
      @clicked="scrollToContact()"
    />
    <v-header
      title="Our mission"
      subtitle="Welcome"
      description="Simplicity Made Easy was created to help others live a simple life and to make it easy to accomplish whatever may be in your way. Let us help you conquer daily stressors by making your home and lifestyle easier on you by De-cluttering and Organizing. Book your Professional Organizer today."
    />
    <v-transition-banner 
      title="Your time is valuable."
      description="Let our organizers help you gain back control of your time. Stop stressing, you've got better things to do!"
    />
    <v-feature-list
      title="Our services"
      subtitle="Discovering Your Needs"
      description=""
      :item-list="[
        {
          title: 'Help moving.',
          description:
            'We help de-cluttering or organizing before or after the move.',
          icon: 'fas fa-box-open',
        },
        {
          title: 'Organizing and de-cluttering.',
          description: 'You choose your need and what you would like tackled. No room is an obstacle.',
          icon: 'fas fa-couch',
        },
        {
          title: 'Prepare an Estate Sale',
          description:
            'We help de-cluttering your home to help it sell or even getting a loved ones home ready and organized for an Estate Sale.',
          icon: 'fas fa-home',
        },
        { title: 'Seasonal decoration', description: 'Need help or don\'t have time to Set-up or Take Down Seasonal Decorations, Event Decorations or Time to get your gardens ready for the next season? Contact us to discuss your needs.', icon: 'fas fa-air-freshener' },
      ]"
    />
    <v-cta 
      subtitle="Special Offer"
      title="Power Hour"
      description="Get 3 hours of non stop go to any room or area you want tackled. This includes a 30 minute consultation beforehand. Client works along with Professional Organizer or even dig Deeper into how or why you hold onto, gather or accumulate stuff. How to let go and learn new strategies to upkeep your desired outcome."
      :button="{
        text : 'Contact-us',
        to : '#'
      }"
      @clicked="scrollToContact()"
    />
    <v-contact id="contact" />
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import Header from '@/components/general/header.vue';
import TransitionBanner from '@/components/general/transtitionBanner.vue';
import FeatureList from '@/components/general/featureList.vue';
import Contact from '@/components/general/contactForm.vue';
import Cta from '@/components/general/callToAction.vue';

export default {
    components: {
        'v-hero': Hero,
        'v-header': Header,
        'v-transition-banner' : TransitionBanner,
        'v-feature-list': FeatureList,
        'v-contact' : Contact,
        'v-cta' : Cta,
    },
    methods:{
        scrollToContact(){
            var VueScrollTo = require('vue-scrollto');

            // var options = {
            //     easing: 'ease-in',
            //     offset: -60,
            //     force: true,
            //     cancelable: true,
            //     x: false,
            //     y: true
            // };

            VueScrollTo.scrollTo('#contact', 500);
        }
    }
};
</script>

<style>
.slide-leave-active,
.slide-enter-active {
  transition: all 0.25s;
}

.slide-leave-to,
.slide-enter-from {
  opacity: 0;
  transform: translateX(-5%);
}

.slide-enter-to {
  opacity: 1;
}
</style>